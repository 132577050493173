import React, {Component} from "react";
import {formatQuery} from "../controllers/utilities/string-utils";
import {connect} from "react-redux";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import {environmentMode} from "../components/lib/ad-manage-api-lib";
import iconGg from "../images/gg-logo.svg";
import Lambda from "../controllers/utilities/aws-lambda";
import {getFullPath} from "../controllers/kaisaku-api";
import cookie from "react-cookies";
import AndroidComm from "../controllers/utilities/unity-android-communication";

class GameSupport2 extends Component {
    constructor(props, context) {
        super(props, context);

        this.params = formatQuery(props.location.search);
        this.state = {
            ready: false,
            iframeKey:"game-support",
            url:''
        };

    }


    async componentDidMount() {        
        // const info = await this.gatherInfo();
        // console.log(info);
        // const url = await getChatUrl2(info, this.params.accessToken);
        // this.setState({ready: true});

        // if (url){
        //     console.log(url);
        // }
        const url = `/game-support1/${this.props.location.search}`;
        this.setState({
            url: url
        });
    }

    async gatherInfo() {
        if (!isValidSession(this.props)){
            let accessToken = cookie.load("_accessToken");
            if (accessToken) {
                if (this.params.transactionId) {
                    return await gatherTransactionMeta(this.params.transactionId, accessToken);
                } else {
                    const res = await getProfile(accessToken);
                    return res.data;
                }
            }
        }else {
            if (this.params.transactionId) {
                return await new KaisakuUserApi(this.props.session).gatherTransactionMeta(this.params.transactionId);
            } else {
                const res = await new KaisakuUserApi(this.props.session).getProfile();
                return res.data;
            }
        }
    }

    render() {
        // if(typeof window !== `undefined`) {
        //     const url = `/game-support/${this.props.location.search}`;
        //     window.location.href = url;
        // }
        return (
            <div style={{position:"absolute",left:"0px",top:"0px", right:"0px",bottom:"0px"}}>
                <header style={{
                    backgroundColor: "#ffffff",
                    color: "#FF3D00",
                    borderBottom: "solid 5px #FF3D00",
                    height: "45px",
                    font: "normal normal 900 22px/40px Segoe UI",
                    paddingLeft: "8px",
                    verticalAlign: "middle",
                    display: "none"
                }}>
                    <div style={{
                        float: "left"
                    }}>
                        {/*<Image src={logo} style={{*/}
                        {/*    paddingTop: "10px",*/}
                        {/*    verticalAlign: "middle"}}/>*/}
                    </div>
                    <div style={{
                        float: "right"
                    }}>
                    <a href="#"
                       onClick={async () => {
                        AndroidComm.sendRawMessage({
                            success: false,
                            message: "user returned"
                        });
                    }}
                       style={{
                        color: "#ffffff",
                        backgroundColor: "#AFAFAF",
                        borderRadius: "5px",
                        float: "right",
                        width: "80px",
                        height: "30px",
                        lineHeight: "40px",
                        marginTop: "5px",
                        marginRight: "10px",
                        font: "normal normal 600 14px/30px Segoe UI",
                        textAlign: "center",
                        verticalAlign: "middle"
                       }}>返回遊戲</a></div>
                </header>
            <div style={{position:"absolute",left:"0px",top:"0px", right:"0px",bottom:"0px"}}>
                <iframe key={this.state.iframeKey} src={this.state.url} style={{width:"100%", height:"100%"}} frameBorder="no" border="0"></iframe>
            </div>
                </div>
    );
    }
}

function mapStateToProps(state) {
    return {session: state.session};
}

export default connect(mapStateToProps)(GameSupport2);

async function getChatUrl2(info, accessToken){
    const res = await Lambda.post(getFullPath("getChat"), info, accessToken);
    // console.log(res.data);
    // {success: true, chaturl: "http://chat-ui-front.s3.ap-southeast-1.amazonaws.c…ms2.jgg18.xyz&hostimg=http%3A%2F%2Fcms2.jgg18.xyz"}
    if ((res.data.success) && (res.data.chaturl)){
        window.location.href = res.data.chaturl;
    }
}

async function gatherTransactionMeta(transactionId, accessToken) {
    const res = await Lambda.get(getFullPath("gatherCustomerServiceMeta"), {transactionId}, accessToken);
    return res.data;
}

export async function getProfile(accessToken) {
    return await Lambda.get(getFullPath("getProfile"), null, accessToken);
}

function isValidSession(props) {
    if (!props) return false;
    return !!props.session && !!props.session.accessToken;
}